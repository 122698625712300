import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CardcontactoComponent } from './components/cardcontacto/cardcontacto.component';
import { ContactoComponent } from './vistas/contacto/contacto.component';
import { PreparacionMuestraComponent } from './vistas/preparacion-muestra/preparacion-muestra.component';
import { CardenfermedadComponent } from './components/cardenfermedad/cardenfermedad.component';
import { FooterComponent } from './components/footer/footer.component';
import { InicioComponent } from './vistas/inicio/inicio.component';



import { NavbarComponent } from './components/navbar/navbar.component';
import { CarrouselComponent } from './components/carrousel/carrousel.component';
import { BannerComponent } from './components/banner/banner.component';
import { EstudioComponent } from './components/estudio/estudio.component';
import { BuscarPipe } from './pipes/buscar.pipe';
import { PoliticaDeCalidadComponent } from './vistas/politica-de-calidad/politica-de-calidad.component';
import { HttpClientModule } from '@angular/common/http';
import { DataService } from './data.service';

import { CardsinicioabajoComponent } from './components/cardsinicioabajo/cardsinicioabajo.component';
import { ModalobrassocialesComponent } from './components/modalobrassociales/modalobrassociales.component';
import { HttpClient } from 'selenium-webdriver/http';
import { ServiciosComponent } from './vistas/servicios/servicios.component';
import { CardserviciosComponent } from './components/cardservicios/cardservicios.component';
import { AreasComponent } from './vistas/areas/areas.component';
import { CardareasComponent } from './components/cardareas/cardareas.component';

//Hash Location
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NotaAnalisisSanguineoComponent } from './vistas/nota-analisis-sanguineo/nota-analisis-sanguineo.component';
import { NoticiasComponent } from './vistas/noticias/noticias.component'

const appRoutes: Routes = [
  {path: 'tomamuestra', component: PreparacionMuestraComponent},
  {path: 'contacto', component: ContactoComponent},
  {path: '', component: InicioComponent},
  {path: 'politica-de-calidad', component: PoliticaDeCalidadComponent},
  {path: 'estudio/:nombre', component: EstudioComponent},
  {path: 'servicios', component: ServiciosComponent},
  {path: 'areas', component: AreasComponent},
  {path:'noticias',component:NoticiasComponent},
  {path:'notaAnalisis',component:NotaAnalisisSanguineoComponent}
];


@NgModule({
  declarations: [
    AppComponent,
    CardcontactoComponent,
    ContactoComponent,
    PreparacionMuestraComponent,
    CardenfermedadComponent,
    InicioComponent,

    NavbarComponent,
    CarrouselComponent,
    BannerComponent,
    EstudioComponent,
    BuscarPipe,
    FooterComponent,
    PoliticaDeCalidadComponent,

    CardsinicioabajoComponent,
    ModalobrassocialesComponent,

    ServiciosComponent,
    CardserviciosComponent,
    AreasComponent,
    CardareasComponent,
    NotaAnalisisSanguineoComponent,
    NoticiasComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes)
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
