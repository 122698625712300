import { Component, OnInit } from '@angular/core';
import { servicio } from 'src/app/clases/servicios';
import { NuestrosServiciosService } from 'src/app/services/nuestros-servicios.service';

@Component({
  selector: 'servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.css']
})
export class ServiciosComponent implements OnInit {
  servicios: servicio[];
  constructor(private apiService: NuestrosServiciosService) { }

  ngOnInit() {
    return this.apiService.getAllServicios().subscribe(data => this.servicios = data);
  }

}
