import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ServiciotomaMuestraService {
  data= [
    {
     "titulo":"ACIDO VAINILLIN MANDELICO",
     "item1":"Durante 4 días no ingerir banana, tomate, chocolate, cacao, cremas, tortas, helados o cualquier alimento que pueda contener vainilla.",
     "item2":"No ingerir aspirinas.",
     "item3":"Beber solamente agua. Se debe evitar la ingesta de te, café y mate.",
     "item4":"Evitar el stress, el ejercicio y el dolor.",
     "item5":"No ingerir diuréticos durante la recolección.",
     "item6":"No fumar.",
     "item7":"El tercer día recolectar orina de 24 horas: descartar la primera micción de la mañana, y recolectar en el bidón  provisto por nuestro laboratorio todas las micciones hasta la primera de la mañana siguiente, inclusive (si además de este estudio su médico le solicitó Orina Completa, la primera micción del día siguiente debe recolectarse en un frasco).",
     "item8":"Mantener la orina refrigerada durante la recolección"
    },
    {
     "titulo":"APOLIPOPROTEINAS",
     "item1":"Se requiere ayuno de 8 a 10 hs"  
    },
  
    {
      "titulo":"HEMOGRAMA",
      "item1": "Se requiere 8 horas de ayuno."
    },
    {
      "titulo":"ERITROSEDIMENTACIÓN (ESD)",
      "item1": "Se requiere 8 horas de ayuno."
    },
    {
      "titulo":"GLUCEMIA",
      "item1": "Se requiere 8 horas de ayuno."
    },
    {
      "titulo":"UREA",
      "item1": "Se requiere 8 horas de ayuno."
    },
    {
      "titulo":"CREATININA",
      "item1": "Se requiere 8 horas de ayuno."
    },
    {
      "titulo":"HEPATOGRAMA",
      "item1": "Se requiere 8 horas de ayuno."
    },
    {
      "titulo":"ACIDO ÚRICO",
      "item1": "Se requiere 8 horas de ayuno.",
      "item2": "Se requiere la orina producida en las 24hs antes del analisis."
    },
    {
      "titulo":"CALCIO",
      "item1":"Se requiere 8 horas de ayuno."  
    },
    {
      "titulo":"FOSFORO",
      "item1": "Se requiere 8 horas de ayuno."
    },
    {
      "titulo":"PROTEINOGRAMA ELECTROFORÉTICO",
      "item1": "Se requiere 8 horas de ayuno."
    }, 
    {
      "titulo":"HEMOGLOBINA GLICOSILADA (HbA1c)",
      "item1": "Se requiere 8 horas de ayuno."
    },
    {
      "titulo":"CHAGAS",
      "item1": "Se requiere 8 horas de ayuno."
    },
    {
      "titulo":"HIV",
      "item1": "Se requiere 8 horas de ayuno."
    },
    {
      "titulo":"HEPATITIS A,B,C,D",
      "item1":"Se requiere 8 horas de ayuno.",
    },
    {
      "titulo":"VDRL",
      "item1": "Se requiere 8 horas de ayuno."
    },
    {
      "titulo":"RUBEOLA",
      "item1": "Se requiere 8 horas de ayuno."
    },
    {
      "titulo":"CITOMEGALOVIRUS",
      "item1": "Se requiere 8 horas de ayuno."
    },
    {
      "titulo":"HERPES",
      "item1": "Se requiere 8 horas de ayuno."
    },
    {
      "titulo":"COLESTEROL TOTAL",
      "item1": "Se requiere 10 horas de ayuno."
    },
    {
      "titulo":"COLESTEROL",
      "item1": "Se requiere 10 horas de ayuno."
    },
    {
      "titulo":"COLESTEROL HDL",
      "item1": "Se requiere 10 horas de ayuno."
    },
    {
      "titulo":"TRIGLICÉRIDOS",
      "item1": "Se requiere 10 horas de ayuno."
    },
    {
      "titulo":"PROTEINAS",
      "item1": "Se requiere 8 horas de ayuno"
    },
    {
      "titulo":"CATECOLAMINAS URINARIAS",
      "item1":"Durante 4 días no ingerir, banana, tomate, chocolate, cacao, cremas, tortas, helados, o cualquier alimento que pueda contener vainilla.",
      "item2":"No ingerir aspirinas.No fumar.No ingerir diureticos.",
      "item3":"Beber solamente agua, evitar la ingesta de te, café y mate.",
      "item4":"Evitar el stress, el ejercicio fisico y el dolor.",
      "item5":"Suspender durante 10 dias la toma de la siguiente medicación previa consulta con el médico, gotas nasales, antihistaminicos, broncodilatadores, supresores del apetito, nitroglicerina, nitroprusiato de sodio, teofilina, alfa2-simpaticomiméticos, antagonistas del calcio, inhibidores de la ECA, alfa metil dopa, inhibidores de la MAO, fenotiazinas, antidepresivos tricíclicos, L-dopa y bloqueantes adrenérgicos alfa y beta.",
      "item6":"El dia de la recoleccion de la muestra descartar la primer orina de la mañana",
      "item7":"Recoger la orina durante todo el dia, hasta la primera de la mañana del dia siguiente inclusive",
      "item8":"Conservar en la heladera hasta su entrega en el laboratorio."
    },
    {
      "titulo":"PROGESTERONA",
      "item1":"Concurrir al laboratorio con 8 hs de ayuno",
      "item2":"En pacientes de sexo femenino salvo indicación médica es aconsejable realizar la extracción entre los días 21 y 23 del ciclo."
    },
    {
      "titulo":"CURVA DE TOLERANCIA A LA GLUCOSA",
      "item1":"Según criterio médico, la curva de tolerancia a la glucosa se puede medir cada 30 minutos, cada 1hs o a las 2hs.",
      "item2":"Concurrir el día de la extracción con ayuno de 8 hs.",
      "item3":"Traer 75 grs. de Glucosa Anhidra y una botella de agua mineral de 500 cm3.",
      "item4":"Después de la primer extracción de sangre se debe tomar la solución preparada en su totalidad , en un periodo de 10 a 15 minutos (para un adecuado desarrollo de la prueba).",
      "item5":"Durante las siguientes 2 hs no ingerir alimento (solo agua), ni desarrollar ninguna actividad, posteriormente se procede a la segunda toma de muestra, salvo indicación medica cada 30 o 60 minutos."
    },
    {
       "titulo":"CATECOLAMINAS PLASMATICA",	
       "item1":"Concurrir al laboratorio con 8 hs de ayuno.",
       "item2":"Durante los 3 días previos a la extracción no ingerir banana, tomate,chocolate, cremas, tortas, helado, ni ningún alimento que contenga vainilla; beber sólo agua.",
       "item3":"No ingerir aspirinas. . Se debe evitar la ingesta de té, café y mate durante el día anterior.",	
       "item4":"No realizar grandes ingestas ni fumar durante la noche anterior. Evitar el stress, el ejercicio y el dolor",
       "item5":".Suspender durante 10 días la toma de la siguiente medicación (previa consulta con elmédico): gotas nasales, antihistamínicos, broncodilatadores, supresores del apetito,  nitroglicerina, nitroprusiato de sodio, teofilina, alfa2-simpaticomiméticos, antagonistas del calcio, inhibidores de la ECA, alfametil dopa, inhibidores de la MAO, fenotiazinas, antidepresivos tricíclicos, L-dopa y bloqueantes adrenérgicos alfa y beta."	 
   },
    
    {
      "titulo":"SEROTONINA PLASMÁTICA",
      "item1":"Concurrir al laboratorio con 8 hs de ayuno.",
      "item2":"Durante 4 días antes de la extracción no ingerir banana, berenjenas, palta, ananá, ciruela, maní ni nueces; no tomar diuréticos , ya que interfieren en la determinación.",
      "item3":"No ingerir infusiones: te mate café y bebidas alcohólicas. No realizar ejercicios extremos.",
      "item4":"El día de la extracción no fumar."
    },
    {
      "titulo":"FLUJO VAGINAL",
      "item1":"48 hs antes de la toma de muestra :",
      "item2":"No tomar antibioticos",
      "item3":"No colocarse cremas, óvulos, talco, antimicóticos, protectores diarios",
      "item4":"La noche anterior, realizar lavado solo con agua, evitando el uso del bidet y baño de inmersión.",
      "item5":"No mantener relaciones sexuales "
    
    }, 
    {
      "titulo":"PSA",
      "item1":"Día previo, evitar actividad física intensa.",
      "item2":"No mantener relaciones sexuales.",
      "item3":"72 Hs Previas, No someterse a tacto rectal y/o ecografías rectales.",
      "item4":"Concurrir al laboratorio con 8 hs de ayuno."
    }, 
    {
      "titulo":"TEST DE GRAHAM",
      "item1":"A la mañana antes de realizar la higiene anal,  SIN QUE TENGA CREMA NI TALCO; tomar una pequeña gasa y pasarlas por los pliegues del ano, colocarla en el recipiente entregado en el laboratorio con el conservante .",
      "item2":"Conservar a temperatura ambiente.",
      "item3":"Repetir la operación durante 7 días Traer al laboratorio."
    },
    {
      "titulo":"PROLACTINA",
      "item1":"Día previo, evitar situaciones de stress, no realizar actividad física, no mantener relaciones sexuales la noche anterior.",
      "item2":"Concurrir al laboratorio con 8 h de ayuno y 1 hora de  vigilia (1 hs despierto).",
      "item3":"Al llegar al laboratorio anunciarse para realizar de 15a 20  minutos de reposo antes de la extracción.",
      "item4":"Informar al laboratorio la medicación que recibe o  ha recibido últimamente.",
      "item5":"MUJERES : realizar la determinación preferentemente entre el 3ºy 5 día del ciclo salvo indicación médica."
    },
    {
      "titulo":"HORMONAS TIROIDEAS T3",
      "item1":"Concurrir al laboratorio con 8 hs de ayuno.En caso de tomar medicación para la glandula tiroide",
      "item2":"la misma deberá ser preferentemente posterior a la extracción de sangre, salvo indicación médica."
    },
    {
      "titulo":"HORMONAS TIROIDEAS T4",
      "item1":"Concurrir al laboratorio con 8 hs de ayuno.En caso de tomar medicación para la glandula tiroide",
      "item2":"la misma deberá ser preferentemente posterior a la extracción de sangre, salvo indicación médica."
    },
    {
      "titulo":"HORMONAS TIROIDEAS T3 LIBRE",
      "item1":"Concurrir al laboratorio con 8 hs de ayuno.En caso de tomar medicación para la glandula tiroide",
      "item2":"la misma deberá ser preferentemente posterior a la extracción de sangre, salvo indicación médica."
    },
    {
      "titulo":"HORMONAS TIROIDEAS T4 LIBRE",
      "item1":"Concurrir al laboratorio con 8 hs de ayuno.En caso de tomar medicación para la glandula tiroide",
      "item2":"la misma deberá ser preferentemente posterior a la extracción de sangre, salvo indicación médica."
    },
    {
      "titulo":"UROCULTIVO",
      "item1":"Se recoge la primera orina de la mañana, por lo menos, tres horas previas de retención.",
      "item2":"No se debe tomar antibióticos desde tres días antes, ni diuréticos el día antes de la recolección de la muestra (salvo indicación médica en contrario).",
      "item3":"Antes de la recolección efectuar una higiene de la zona genital externos con agua y jabón , secar con una toalla limpia.",
      "item4":"Eliminar el primer chorro y juntar el chorro medio.",
      "item5":"En caso de mujer adulta se aconseja un tampón vaginal.",
      "item7":"En caso del hombre higiene igual pero también retracción de prepucio juntando el chorro medio."
    },
    {
      "titulo":"DOPAMINA PLASMATICA",
      "item1":"Concurrir al laboratorio con 8 hs de ayuno.",
      "item2":"Durante los 3 días previos a la extracción no ingerir banana, tomate, chocolate, cremas, tortas, helado, ni ningún alimento que contenga vainilla, 24 HS antes a la toma de muestra no ingerir cafe,te ni mate. ",
      "item3":"Evitar el ejercicio físico, no tomar aspirinas ni diuréticos, no fumar."
    },  
    {
      "titulo":"VALPROATO",
      "item1":"Se recomienda ayuno previo de 8 hs.",
      "item2":"Concurrir para la extracción en la fase valle   de la toma de la medicación (lo más cerca posible al horario de la próxima toma).",
      "item3":"También según indicación médica puede indicarse en la fase pico ,la extracción debe realizarse entre 4 y 8 horas después de la toma de la medicación.",
      "item4":"Indicar dosis y horario de la última toma."
    },
    {
      "titulo":" PARASITOLOGICO",
      "item1":"Retira del laboratorio el frasco correspondiente con el líquido conservante.",
      "item2":"No se debe tomar ni volcar.",
      "item3":"Durante siete días consecutivos recoger día por medio una pequeña muestra de la deposición de materia fecal , colocarla con una espatula en el recipiente entregado.",
      "item4":"Traer el frasco al laboratorio.",
      "item5":"Conservar a temperatura ambiente",
      "item6":"Si se observara un parásito o cuerpo extraño colocarlo en un recipiente estéril con agua y remitirlo al laboratorio lo antes posible."
    },
    {
      "titulo":"FSH",
      "item1":"Concurrir al laboratorio con 8 hs de ayuno.",
      "item2":"En pacientes de sexo femenino, el estudio se debe realizar entre los días 3-5 del ciclo."
    },
    {
      "titulo":"LH",
      "item1":"Concurrir al laboratorio con 8 hs de ayuno.",
      "item2":"En pacientes de sexo femenino, el estudio se debe realizar entre los días 3-5 del ciclo."
    },
    {
      "titulo":"ESTRADIOL",
      "item1":"Concurrir al laboratorio con 8 hs de ayuno.",
      "item2":"En pacientes de sexo femenino, el estudio se debe realizar preferentemente entre los días 3-5 del ciclo menstrual excepto indicación médica."
    },
    {
      "titulo":"GONADOTROFINA CORIONICA CUANTITATIVA (Determina la HCG intacta y subunidades –β libres de HCG)",
      "item1":"Se requiere 8 horas de ayuno.",
    },
    {
      "titulo":"ATPO (ANTIPEROXIDASA)",
      "item1":"Concurrir al laboratorio con 8 hs de ayuno.",
      "item2":"En caso de tomar medicación para la glandula tiroide la misma deberá ser preferentemente posterior a la extracción de sangre, salvo indicación médica."
    },
    {
      "titulo":"17 HIDROXIPROGESTERONA",
      "item1":"Concurrir al laboratorio con 8 hs de ayuno.",
      "item2":"En pacientes de sexo femenino, el estudio se debe realizar preferentemente entre los días 3-5 del ciclo menstrual excepto indicación médica"
    },
    {
      "titulo":"PROGESTERONA",
      "item1":"Concurrir al laboratorio con 8 hs de ayuno.",
      "item2":"En pacientes de sexo femenino, el estudio se debe realizar entre los días 3-5 del ciclo."
    },
    {
      "titulo":"ORINA DE 24 HS",
      "item1":"El día de recolección de la muestra, descartar la  primera orina de la mañana.",
      "item2":"Recoger la orina durante todo el día, hasta la primera de la mañana del día siguiente, inclusive. ",
      "item3":"Durante la recolección, mantener el recipiente en la heladera."
    },
    {
      "titulo":"CLEARENCE DE CREATININA",
      "item1":"El día de recolección de la muestra, descartar la  primera orina de la mañana.",
      "item2":"Recoger la orina durante todo el día, hasta la primera de la mañana del día siguiente, inclusive. ",
      "item3":"Durante la recolección, mantener el recipiente en la heladera."
    },
    {
      "titulo":"URICOSURIA",
      "item1":"El día de recolección de la muestra, descartar la  primera orina de la mañana.",
      "item2":"Recoger la orina durante todo el día, hasta la primera de la mañana del día siguiente, inclusive. ",
      "item3":"Durante la recolección, mantener el recipiente en la heladera."
    },
    {
      "titulo":"PROTEINURIA",
      "item1":"El día de recolección de la muestra, descartar la  primera orina de la mañana.",
      "item2":"Recoger la orina durante todo el día, hasta la primera de la mañana del día siguiente, inclusive. ",
      "item3":"Durante la recolección, mantener el recipiente en la heladera."
    },
    {
      "titulo":"IONOGRAMA EN ORINA",
      "item1":"El día de recolección de la muestra, descartar la  primera orina de la mañana.",
      "item2":"Recoger la orina durante todo el día, hasta la primera de la mañana del día siguiente, inclusive. ",
      "item3":"Durante la recolección, mantener el recipiente en la heladera."
    },
    {
      "titulo":"IONOGRAMA PLASMATICO",
      "item1":"Se requiere 8 horas de ayuno.",
    },
    {
      "titulo":"SANGRE OCULTA EN MATERIA FECAL",
      "item1":"La muestra no debe ser recolectada durante el periodo menstrual o si el paciente sufre sangrado de hemorroides ni sangrado en orina.",
      "item2":"48 hs antes no consumir alcohol ni aspirinas.",
      "item3":"Recoger en un recipiente estéril una sola deposición de materia fecal.",
    },
    {
      "titulo":"FOSFATURIA",
      "item1":"El día de recolección de la muestra, descartar la  primera orina de la mañana.",
      "item2":"Recoger la orina durante todo el día, hasta la primera de la mañana del día siguiente, inclusive. ",
      "item3":"Durante la recolección, mantener el recipiente en la heladera."
    },
    {
      "titulo":"CALCIURIA",
      "item1":"El día de recolección de la muestra, descartar la  primera orina de la mañana.",
      "item2":"Recoger la orina durante todo el día, hasta la primera de la mañana del día siguiente, inclusive. ",
      "item3":"Durante la recolección, mantener el recipiente en la heladera."
    },
    {
      "titulo":"ESPERMOCULTIVO",
      "item1":"Tres días previos a la recolección de la muestra:",
      "item2":"No tomar antibióticos, ni colocarse cremas",
      "item3":"No mantener relaciones sexuales el día anterior",
      "item4":"Realizarse higiene previa, con jabón blanco y enjuagarse con abundante cantidad de agua"
    },
    {
      "titulo":"ESPERMOGRAMA",
      "item1":"Tres días previos a la recolección de la muestra:",
      "item2":"No tomar antibióticos, ni colocarse cremas",
      "item3":"Abstinencia sexual(mínimo 72 hs)",
      "item4":"Realizarse higiene previa, con jabón blanco y enjuagarse con abundante cantidad de agua"
      
    },

    {
      "titulo":"ARTRITEST",
      "item1":"Ayuno de 8-10 horas"
    },
    {
      "titulo":"ASO (ANTIESTREPTOLISINA O)",
      "item1":"Ayuno de 8-10 horas"
    },
    {
      "titulo":"BRUCELOSIS IGG/IGM",
      "item1":"Ayuno de 8-10 horas"
    },
    {
      "titulo":"CHLAMYDIA – TRACHOMATIS IGG/IGM/IGA",
      "item1":"Ayuno de 8-10 horas"
    },
    {
      "titulo":"CHLAMYDIA PSITACCI IGG/IGM",
      "item1":"Ayuno de 8-10 horas"
    },
    {
      "titulo":"CHLAMYDIA TRACHOMATIS (ANTÍGENO)",
      "item1":"Mujeres: ",
      "item2":"48 horas antes de la toma de muestra, no tomar antibióticos, no usar cremas, óvulos, talco, antimicóticos o protectores diarios.La noche anterior, realizar lavado solo con agua, evitando el uso de bidet y baño de inmersión.No mantener relaciones sexuales 48 horas antes.Preferentemente realizar el estudio a mitad del ciclo.",
      "item3":"Hombres: ",
      "item4":"El paciente debe tener una retención urinaria de al menos una hora.No usar antibióticos ni cremas los días previos.Debe higienizarse los genitales con jabón blanco y enjuagarse con abundante agua. No mantener relaciones sexuales al menos 48 horas antes."
    },
    {
      "titulo":"EPSTEIN BARR  IGG IGM",
      "item1":"Ayuno de 8-10 horas"
    },
    {
      "titulo":"FACTOR REUMATOIDEO ",
      "item1":"Ayuno de 8-10 horas"
    },
    {
      "titulo":"HELICOBACTER PYLORI IGG/IGM/IGA",
      "item1":"Ayuno de 8-10 horas"
    },
    {
      "titulo":"MONONUCLEOSIS (MONOTEST)",
      "item1":"Ayuno de 8-10 horas"
    },
    {
      "titulo":"PAROTIDITIS IGG/IGM",
      "item1":"Ayuno de 8-10 horas"
    },
    {
      "titulo":"PARVOVIRUS IGG/IGM",
      "item1":"Ayuno de 8-10 horas"
    },
    {
      "titulo":"PCR CUANTITATIVA",
      "item1":"Ayuno de 8-10 horas"
    },
    {
      "titulo":"PCR (PROTEINA C REACTIVA ULTRASENSIBLE)",
      "item1":"Ayuno de 8-10 horas"
    },
    {
      "titulo":"ROSSE RAGAN LÁTEX",
      "item1":"Ayuno de 8-10 horas"
    },
    {
      "titulo":"RUBEOLA IGG/IGM",
      "item1":"Ayuno de 8-10 horas"
    },
    {
      "titulo":"SARAMPIÓN IGG/IGM",
      "item1":"Ayuno de 8-10 horas"
    },
    {
      "titulo":"TEST DE AVIDEZ",
      "item1":"Ayuno de 8-10 horas"
    },
    {
      "titulo":"TOXOPLASMOSIS IGG/IGM ",
      "item1":"Ayuno de 8-10 horas"
    },
    {
      "titulo":"UREAPLASMA UREALYTICO",
      "item1":"Mujeres: ",
      "item2":"48 horas antes de la toma de muestra, no tomar antibióticos, no usar cremas, óvulos, talco, antimicóticos o protectores diarios.La noche anterior, realizar lavado solo con agua, evitando el uso de bidet y baño de inmersión.No mantener relaciones sexuales 48 horas antes. Preferentemente realizar el estudio a mitad del ciclo.",
      "item3":"Hombres: ",
      "item4":"El paciente debe tener una retención urinaria de al menos una hora.No usar antibióticos ni cremas los días previos.Debe higienizarse los genitales con jabón blanco y enjuagarse con abundante agua.No mantener relaciones sexuales al menos 48 horas antes."
    },
    {
      "titulo":"VARICELA ZOSTER IGG/IGM",
      "item1":"Ayuno de 8-10 horas"
    },
    {
      "titulo":"ALERGENOS IgE ESPECÍFICAS",
      "item1":"Ayuno de 8-10 horas"
    },
    {
      "titulo":"ALFA FETO PROTEINA",
      "item1":"Ayuno de 8-10 horas"
    },
    {
      "titulo":"B-HCG",
      "item1":"Ayuno de 8 horas"
    },
    {
      "titulo":"CA 15-3 (MAMA)",
      "item1":"Ayuno de 8 horas"
    },
    {
      "titulo":"CA 19-9 (COLON)",
      "item1":"Ayuno de 8 horas"
    },
    {
      "titulo":"CA 21-1 CYFRA 21-1 (PULMÓN)",
      "item1":"Ayuno de 8 horas"
    },
    {
      "titulo":"CA 72-4 (ESTÓMAGO)",
      "item1":"Ayuno de 8 horas"
    },
    {
      "titulo":"CA 125 (OVARIO)",
      "item1":"Ayuno de 8 horas"
    },
    {
      "titulo":"CEA (ANTIGENO CARCINO EMBRONARIO)",
      "item1":"Ayuno de 8 horas"
    },
    {
      "titulo":"CHLAMYDIA PNEUMONIAE IGG/IGM",
      "item1":"Ayuno de 8-10 horas"
    },
    {
      "titulo":"ANTICUERPOS ANTI NUCLEARES (FAN)",
      "item1":"Ayuno de 8-10 horas"
    },
    {
      "titulo":"COMPLEMENTO TOTAL CH50",
      "item1":"Ayuno de 8-10 horas"
    },
    {
      "titulo":"FRACCIÓN C3 ",
      "item1":"Ayuno de 8-10 horas"
    },
    {
      "titulo":"FRACCIÓN C4",
      "item1":"Ayuno de 8-10 horas"
    },
    {
      "titulo":"IgA",
      "item1":"Ayuno de 8-10 horas"
    },
    {
      "titulo":"IgD",
      "item1":"Ayuno de 8-10 horas"
    },
    {
      "titulo":"IgE",
      "item1":"Ayuno de 8-10 horas"
    },
    {
      "titulo":"IgG",
      "item1":"Ayuno de 8-10 horas"
    },
    {
      "titulo":"IgM",
      "item1":"Ayuno de 8-10 horas"
    },
    {
      "titulo":"PSA LIBRE",
      "item1":"Día previo, evitar actividad física intensa.",
      "item2":"No mantener relaciones sexuales.",
      "item3":"72 Hs Previas, no someterse a tacto rectal y/o ecografías rectales.",
      "item4":"Concurrir al laboratorio con 8 hs. de ayuno"
    }




  
  ]
  
  constructor() { }
}
