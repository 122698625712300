import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery'

@Component({
  selector: 'carrousel',
  templateUrl: './carrousel.component.html',
  styleUrls: ['./carrousel.component.css']
})
export class CarrouselComponent implements OnInit {

  constructor() { }

  async ngOnInit(){
    for (var i = 1; i < 100; i++) {
      await setTimeout(this.movercarrousel,2000)
      await setTimeout(this.movercarrousel,4000)
      await setTimeout(this.movercarrousel,6000)
      await setTimeout(this.movercarrousel,8000)
      
   }
    
  }
  movercarrousel(){
    $("#botonNext").click();
    $("#botonNext2").click();
    $("#botonNext3").click();
  }

}
