import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  url:string =  'https://laboratorioromano.com.ar';
  urlAPI:string = 'https://wgxaknaowg.execute-api.us-east-1.amazonaws.com';
  constructor() { }
}
