import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/jsonservice.service';
import { estudio } from '../../clases/estudio';
import { ServiciotomaMuestraService } from 'src/app/services/serviciotoma-muestra.service';

@Component({
  selector: 'estudio',
  templateUrl: './estudio.component.html',
  styleUrls: ['./estudio.component.css']
})
export class EstudioComponent implements OnInit {

  @Input() public estudio: any;
  estudiotitulo: string;
  estudiomostrar: any;
  estudios: any[];

  constructor(private route: ActivatedRoute, private tomamuestra: ServiciotomaMuestraService) {
    //CONSIGUE LA RUTA CON EL NOMBRE DE LA MUESTRA
    this.estudiotitulo=route.snapshot.paramMap.get('nombre');
   }
  
    Comparar () {
      this.estudios.forEach(element => {
        // BUSCA ENTRE TODOS LOS TITULOS DEL ARRAY ESTUDIOS CUAL COINCIDE CON EL PARAMETRO TRAIDO,
        // SI LO ENCUENTRA, SE LE ASIGNA ESE VALOR A LA VARIABLE ESTUDIOMOSTRAR
        if(element.titulo == this.estudiotitulo) {
          this.estudiomostrar = element;
        }
      });
      return true;
    }

  ngOnInit() {
    this.estudios = this.tomamuestra.data
    this.Comparar();
  }
}
