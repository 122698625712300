import { Component, OnInit } from '@angular/core';
import { servicio } from 'src/app/clases/servicios';
import { NuestrosServiciosService } from 'src/app/services/nuestros-servicios.service';

@Component({
  selector: 'cardareas',
  templateUrl: './cardareas.component.html',
  styleUrls: ['./cardareas.component.css']
})
export class CardareasComponent implements OnInit {

  servicios:any[] = [
    {
        "titulo":"Química clinica"
    },

    {
        "titulo":"Hematología"
    },

    {
        "titulo":"Hemostasia"
    },

    {
        "titulo":"Endocrinología"
    },

    {
        "titulo":"Inmunoserología"
    },

    {
        "titulo":"Parasitología"
    },

    {
        "titulo":"Bacteriología"
    },

    {
        "titulo":"Virología"
    },
    {
	    "titulo":"Serología"
    },
    {
        "titulo":"Micología"
    }
]
  
  
  


  constructor(private apiService: NuestrosServiciosService) { }

   ngOnInit() {
     
      
     
      var contador = 1;  
     
      var x = [];
      this.servicios.forEach(function(servicio) {
         var object:any = {
           titulo:'',
           contador:''

         }; 
         object.titulo = servicio.titulo;
         object.contador = contador;
         x.push(object);
         contador++;
      });

      this.servicios = x;

   
    
   
  
  }

  esPar(contador){
    if(contador % 2 == 0){
      return true;
    }
    return false;

  }

  esImpar(contador){
    if(contador % 2 != 0 ){
      return true;
    }
    return false;
  }



}
