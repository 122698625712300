import { Component, OnInit } from '@angular/core';
import { servicio } from '../../clases/servicios';
import { NuestrosServiciosService } from '../../services/nuestros-servicios.service';

@Component({
  selector: 'cardservicios',
  templateUrl: './cardservicios.component.html',
  styleUrls: ['./cardservicios.component.css']
})
export class CardserviciosComponent implements OnInit {

  servicios: servicio[];

  constructor(private apiService: NuestrosServiciosService) { }

  ngOnInit() {
    return this.apiService.getAllServicios().subscribe(data => this.servicios = data);
  }

}
