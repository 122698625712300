import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cardsinicioabajo',
  templateUrl: './cardsinicioabajo.component.html',
  styleUrls: ['./cardsinicioabajo.component.css']
})
export class CardsinicioabajoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
